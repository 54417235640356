<template>
    <div class="searching-form-select">
        <select :name="select.name" id="" @input="$emit('selected', $event.target.value)">
            <option v-for="(option, index) in select.options"
                    :key="index"
                    :disabled="option.disabled"
                    :selected="option.selected"
                    :value="option.value">{{ option.holder }}
            </option>
        </select>
        <span class="searching-form-select-triangle"></span>
    </div>
</template>

<script>
    export default {
        props: {
            select: {
                name: {
                    type: String,
                    default: ""
                },
                options: {
                    type: Object,
                    default: []
                }
            }
}
    }
</script>

<style lang="scss" scoped>
.searching-form-select{
    position: relative;
    // width: 150px;
    select{
        appearance: none;
        position: relative;
        width: 100%;
        height: 50px;
        border: transparent;
        border-radius: 0.4em;
        padding: 0 1em;
        color: $black;
        font-size: 1em;
        font-weight: 700;
        &:focus{
            border: 2px solid $accent-dark;
            outline: none;
        }
    }
    option{
        background-color: $accent;
        color: $white;
        option:hover{
            background-color: $accent-dark;
        }
    }
    .searching-form-select-triangle{           
        border-top: 10px solid $accent-dark;
        border-bottom: 10px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        top: 50%;
        right: 5%;
        position: absolute;
        display: inline-block;
        transform: translateY(-20%);
    }
}
</style>